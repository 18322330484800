@import "../colors";

.businesshome {
  display: flex;
  flex-direction: column;
  min-height: 71vh !important;
  padding: 2rem;
  .resoponsiveTable{
    overflow-x: auto;
  }
  .monthlyDispatchInput {
    min-width: 150px !important;
  }
  .orderBtn{
     text-align: center;
     padding:2rem 0;
    Button{
      background-color: #67214B;
      color: white;
      padding: 0 3.2rem;
    }
  } 
   
  table {
    width: 100% !important;
    border-collapse: collapse;
    border: 1px solid $purple; // Border for the entire table
  }

  th {
    background-color: $indigo; // $Purple header background color
    color: white; // Header text color
    font-weight: bold; // Header text bold
    padding: 10px; // Header cell padding
    text-align: left; // Header text alignment
    border: 1px solid $dark; // Border for header cells
  }

  td {
    padding: 10px; // Cell padding
    border: 1px solid $dark; // Border for data cells
    // display: flex;
    align-items: center;
    justify-content: center;
    > button {
      background-color: $indigo;
      padding: 0.5rem;
      border-radius: 5px;
      color: $white;
      &:hover {
        opacity: 0.5;
        background-color: $blue;
      }
    }
    > input {
      align-self: stretch;
      // width: fit-content;
      padding: 1rem;
      border-radius: 5px;
      border: 1px solid #ccc;
      outline: none;
      margin: 1rem 0;
    }
  }

  tr:nth-child(even) {
    background-color: #f9f9f9; // Even row background color
  }

  tr:hover {
    background-color: $gray3; // Hovered row background color
  }

  > p {
    // background-color: aqua;
    text-align: center;
    position: absolute;
    bottom: 10vh;
    // left:0;
  }
}

@media screen and (max-width: 900px) {
  .myorders {
    // padding: 0rem;
    // overflow-x: scroll;

    table {
      width: 100vw;
    }
    td {
      padding: 10px; // Cell padding
      // border: 1px solid $dark; // Border for data cells
      // display: flex;
      align-items: center;
      justify-content: center;
      > button {
        background-color: $indigo;
        padding: 0.5rem;
        border-radius: 5px;
        color: $white;
        font-size: x-small;
        &:hover {
          opacity: 0.5;
          background-color: $blue;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .businesshome{
    padding: 1rem;
  }
  nav{
    padding: 0.7rem 1rem
  }
}
