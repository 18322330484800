@import "colors";

.phoneNumberInput{
  border: 1px solid rgba(0,0,0,0.3);
 
  border-radius:6px;
  display: flex;
   div{
    padding: 9px;
    background-color:#997F98 ;
    overflow: hidden ;
    width:50px;
    color: #FFF;
   }
   input:focus{
    outline: none;
   }
   input{
    padding-left: 7px;
    width: 100%;
    border-radius: 20px;
   }
}

.dontAccount{
  margin: 10px 0;
}

nav {
 width :100%;
    padding :0.7rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    background-color: $first;
    top: 0%;
    z-index: 10;
//   background-color: aqua;

   
      > img {
         width:100px;
         // height: 15vw;
         object-fit: contain;
         cursor: pointer;
      // /   animation: imgUp 0.7s linear infinite alternate ;
      }

     > h1 {
        text-transform: uppercase;
     }
     > main {
        width: 70%;
        display: flex;
        justify-content: flex-end;
         > a {
            // background-color: $purple;
            padding: 0.5rem;
            border-radius: 5px;
         color : $dark  ;
         margin : 1rem;
         font-size: large;
          &:hover{
            color: $first;
            background-color: $indigo;
          }
          &:active {
            color: $indigo;
            font-weight: bold;
          }
      
          &.active { /* Add style for active link */
            text-decoration: underline;
          }
          
      }
      > button {
         background-color: $purple;
            padding: 0.5rem;
            border-radius: 5px;
         color : $first;
         margin : 1rem;
         font-size: large;
          &:hover{
            background-color: $blue;
          }
       }
     }
      

}
.active{
   text-decoration: underline;
   color:$indigo;
   color: $dark;
   font-weight: bold;
 }
 .cta-buttons {
  //  margin-top: 20px;
 }
 
 .cta-buttons .btn {
   display: inline-block;
   background-color: #007bff;
   color: #fff;
   padding: 10px 20px;
   text-decoration: none;
   border-radius: 5px;
   margin-right: 10px;
 }
