@import "../colors";


.side-drawer {
    display:flex;
    margin: 0rem 3rem ;
    padding: 2rem 0;
    height: fit-content;
    gap:2rem;
    width: 350px;
    border-radius: 3px;
    background-color: $indigo;
    flex-direction: column;
    justify-content: space-evenly;
    position: sticky;
    top:8.1rem;
    >a {
        text-align: center;
        color: $dark;
        padding:0.5rem;
        &:hover{
          
            color: $first;
            // background-color: $blue;
            width:80%;
            border-radius: 5px;
            align-self: center;
            // padding:0.5rem;
            color: $first;
            
          }
          &:active {
            color: $indigo;
            font-weight: bold;
          }
          &.active {
            color: $indigo;
            text-decoration: underline;
            font-weight: bold;

          }

    }
   
  }
  
