@import "../colors.scss";

.settings {
  // display: flex;
  // width: 100%;
  min-height: 90vh;

  // flex-direction: row;
  // overflow: hidden !important;
  .Tabs {
    // display: flex;
    margin: 0rem 3rem;
    height: 60vh;
    width: 30vw;
    max-width: 300px;
    border-radius: 3px;
    // background-color: $indigo;
    flex-direction: column;
    justify-content: space-evenly;
    border: 0.7px solid $gray1;
    align-self: center;
    display: none;
  }
}
.chakra-tabs__tab-panel {
  width: 100%;
}
.TabContainer {
  display: flex;
  flex-direction: column;
  .button {
    margin-bottom: 0.9rem;
    padding: 0.7rem;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    background-color: $blue;
    color: $first;
    //  width: 40%;
    font: 500 1rem $text1;
    &:hover {
      // opacity :0.5;
      background-color: $indigo;
    }
  }
  .list-Item {
    width: 100%;
    justify-content: space-between;
    margin: 0.3rem 0.5rem;
  }
}

.categoryForm {
  // margin :1.5rem 0 ;

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.categorydiv {
  flex-direction: column;
  //  background-color: bisque;
  // height: 3rem;
  display: flex;
  align-items: left;
  justify-content: flex-end;
  width: 100%;
  // height: 8vh;
  // margin : 1rem;
}

.categoryLable {
  font: 400 1 $text1;
  // width: 20%;
  margin: 0.5rem 0rem;
  color: $blue;
}
.categoryInput {
  align-self: stretch;
  width: 100%;
  padding: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: none;
}
.categoryButton {
  margin: 1.5rem;
  padding: 0.7rem;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: $blue;
  color: $first;
  width: 40%;
  font: 500 1rem $text1;
  &:hover {
    // opacity :0.5;
    background-color: $indigo;
  }
}
