@import "colors";

.loginBussinessContainer {
    width : 100%;
    height: 100vh !important;
    background-color: $white !important;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    > main {
       background-color: $white !important;
        height: 60vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        // padding: 3rem;
       
        >h1 {
            text-align: center;
            font-weight: 400;
            font-size:50px ;
            font-family :  $text3; 
            color: $purple;
            // margin: 2rem;
        }
        >h4{
            font-size:medium;
            text-align: center;
            font-weight: 400;
            color: $blue;
            margin-top:2rem;
        }

        > form{
            //   background-color: antiquewhite;
            width: 80%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
             > div{
                flex-direction: column;
                // background-color: bisque;
                // height: 3rem;
                display: flex;
                align-items: left;
                justify-content: flex-end;
                width: 40%;
                // height: 8vh;
                margin : 1rem;
                > label {
                    font : 400 1 $text1;
                    // width: 20%;
                    margin: 0.5rem ;
                    color: $blue;
                }
               
                > input {
                    align-self:stretch ;
                    // width: fit-content;
                    padding: 1rem;
                    border-radius: 5px;
                    border: 1px solid #ccc;
                    outline: none;

                }
             }
            
             >div:nth-child(2){
                >div{
                    flex-direction: row;
                display:   flex;
                //  background-color: antiquewhite;
                justify-content: space-between;
                
                }
             }
              > button {
                 margin-top :0.3rem ;
                  padding: 0.7rem;
                  cursor: pointer;
                  border: none;
                   border-radius: 5px;
                   background-color: $blue;
                   color: $first;
                   width: 40%;
                   font: 500 1rem $text1;
                   &:hover{
                // opacity :0.5;
                background-color: $indigo;
                   }

              }
              >a {
                margin: 1rem 0;
               width: 40%;;
                text-align: center;
                font : 400 1 $text1 ;
             
                >span{
                    color: $blue;
                    cursor: pointer;
                    text-decoration: underline;
                }
              }
              
        }
        

    }
    >div{
        display: flex;
        align-self: flex-end;
        // justify-content :flex-end;
        // background-color: aqua;
         width: 100vw;
         height:40vh;
        > img {
       width: 100vw;
         height: 100%;
            align-self: flex-end; /* Align image to the bottom */
             margin-top: auto; /* Push image to the bottom */
        }

    }
   
   

    
}

.resetForm {
    margin :1.5rem 0 ;
    // background-color: bisque;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

}
.resertdiv{
    flex-direction: column;
                // background-color: bisque;
                height: 3rem;
                display: flex;
                align-items: left;
                justify-content: flex-end;
                width: 80%;
                height: 8vh;
                margin : 1rem;
}

.resertLable {
    font : 400 1 $text1;
                    // width: 20%;
                    margin: 0.5rem ;
                    color: $blue;
}
.resetInput{
    align-self:stretch ;
                     width: 100%;
                    padding: 1rem;
                    border-radius: 5px;
                    border: 1px solid #ccc;
                    outline: none;
}
.resetButton{
   
        margin :1.5rem ;
         padding: 0.7rem;
         cursor: pointer;
         border: none;
          border-radius: 5px;
          background-color: $blue;
          color: $first;
          width: 40%;
          font: 500 1rem $text1;
          &:hover{
       // opacity :0.5;
       background-color: $indigo;
}
}
.otpDiv{
    display: flex;
    justify-content: space-around;
}
@media screen and (max-width: 700px) {
    .loginBussinessContainer {
        // background-color: purple !important;
        > main {
            background-color: $white !important;
              height: 80vh;
             width: 100%;
             display: flex;
             flex-direction: column;
             align-items: center;
             justify-content: center;
            //  justify-content: flex-end;
             // padding: 3rem;
            
             >h1 {
                 text-align: center;
                 font-weight: 400;
                 font-size:30px ;
                 font-family :  $text3; 
                 color: $purple;
                //  margin: 3rem;
             }
             >h4{
                 font-size:medium;
                 text-align: center;
                 font-weight: 400;
                 color: $blue;
                  margin-bottom: 1.5rem;
             }
     
             > form{
                //    background-color: antiquewhite;
                 width: 80%;
                 display: flex;
                 flex-direction: column;
                 justify-content: flex-end;
                 align-items: center;
                  > div{
                     flex-direction: column;
                     // background-color: bisque;
                     height: 3rem;
                     display: flex;
                     align-items: left;
                     justify-content: flex-end;
                     width: 100%;
                     height: 8vh;
                     margin : 1rem;
                     > label {
                         font : 400 1 $text1;
                         // width: 20%;
                         margin: 0.5rem ;
                         color: $blue;
                     }
                     > input {
                         align-self:stretch ;
                         // width: fit-content;
                         padding: 1rem;
                         border-radius: 5px;
                         border: 1px solid #ccc;
                         outline: none;
     
                     }
                  }
                 
                  >div:nth-child(2){
                     >div{
                         flex-direction: row;
                     display:   flex;
                     //  background-color: antiquewhite;
                     justify-content: space-between;
                     
                     }
                  }
                   > button {
                    //   margin:1rem ;
                       padding: 0.7rem;
                       cursor: pointer;
                       border: none;
                        border-radius: 5px;
                        background-color: $blue;
                        color: $first;
                        width: 100%;
                        font: 500 1rem $text1;
                        &:hover{
                     // opacity :0.5;
                     background-color: $indigo;
                        }
     
                   }
                   >a {
                     margin: 1rem 0;
                    width: 100%;;
                     text-align: center;
                     font : 400 1 $text1 ;
                  
                     >span{
                         color: $blue;
                         cursor: pointer;
                         text-decoration: underline;
                     }
                   }
                   
             }
             
     
         }
         >div{
            display: flex;
            align-self: flex-end;
            // justify-content :flex-end;
            // background-color: aqua;
             width: 100vw;
             height:20vh;
            > img {
           width: 100vw;
             height: 100%;
                align-self: flex-end; /* Align image to the bottom */
                 margin-top: auto; /* Push image to the bottom */
            }
    
        }
    }
}