@import "../colors";


.userDetails {
    
    display:flex;
    width: 100vw;
    min-height: 80vh;
    flex-direction: row;
    background-color: white;
    // margin-top: 2rem;
    position: relative;

   
  }
  .searchbar{
    margin: 1rem 0rem;
    max-width: 60%;
  }

  .downloadButton{
    position: absolute; /* Position the button */
  top: 10px; /* Distance from the top of the container */
  right: 10px; 
  display: flex;
  padding: 5px 10px;
  background-color: $green; /* Button background color */
  color: #ffffff; /* Button text color */
  text-decoration: none; /* Remove underline from the link */
  border: none;
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;
  }
  
  .side-drawer.open {
    transform: translateX(0);
  }
  
  .toggle-button {
    position: absolute;
    top: 1rem;
    left: 1rem;
    font-size: 1.5rem;
    cursor: pointer;
  }