@import "../colors";

.myorders{
    display: flex;
    width: 100vw !important;
    min-height: 80vh;
    // align-items: center;
    // justify-content: center;
    padding:1rem;
    max-width: 100%; // Ensure the container doesn't exceed the viewport width
    overflow-x: auto;


        table {
        
          width: 100%;
          border-collapse: collapse;
          // border: 1px solid $purple; // Border for the entire table

        }
        .orderTd{
          // display: flex;
          // justify-content: center;
        }
        th {
          background-color: $indigo; // $Purple header background color
          color: white; // Header text color
          font-weight: bold; // Header text bold
          padding: 10px; // Header cell padding
          text-align: left; // Header text alignment
          // border: 1px solid $dark; // Border for header cells
        }
      
        td {
          padding: 10px; // Cell padding
          // border: 1px solid $dark; // Border for data cells
          // display: flex;
          align-items: center;
          justify-content: center;
          > button {
              background-color: $indigo;
              padding: 0.5rem;
              border-radius: 5px;
              color: $white;
              &:hover{
                opacity :0.5;
                background-color: $blue;
                   }
          }
         
        }
      
        tr:nth-child(even) {
          background-color: #f9f9f9; // Even row background color
        }
      
        tr:hover {
          background-color: $gray3; // Hovered row background color
        }
      
}

@media screen and (max-width: 700px) {
  .myorders{
    // padding: 0rem;
  
   table {
    overflow-x: scroll;
   }
   td {
    padding: 10px; // Cell padding
    // border: 1px solid $dark; // Border for data cells
    // display: flex;
    align-items: center;
    justify-content: center;
    > button {
        background-color: $indigo;
        padding: 0.5rem;
        border-radius: 5px;
        color: $white;
        font-size: x-small;
        &:hover{
          opacity :0.5;
          background-color: $blue;
             }
    }
   
  }

  
  }
}
