@import "./colors.scss";

.maintenance-container {
    width: 100%;
    min-height: 100vh;
   display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: whitesmoke;
    text-align: center;
    padding: 50px;
  }
  
  .maintenance-image {
    width: 300px;
    height: auto;
    margin-bottom: 30px;
    align-self: center;
    // background-color: azure;
  }
  
  .maintenance-heading {
    font: 24px $text1;
    margin-bottom: 10px;
    
  }
  
  .maintenance-text {
    font: 16px $text1;
    color: #666;
  }