@import "../colors";

.Products{
  //  display:flex;
    // width: 100vw;
    // background-color: aqua;
    // align-items: center;
    // justify-content: center;
    .composition-column {
       
         max-width: 30vw;
        
        
        // background-color: aqua;
      }
      .column-5vw {
       
        max-width: 5vw;
       
       
       // background-color: aqua;
     }
     .column-10vw {
       
        max-width: 10vw;
       
       
       // background-color: aqua;
     }
     .column-15vw {
       
        max-width: 15vw;
       
       
       // background-color: aqua;
     }
     
      Td {
        white-space: normal;
        max-width: 20vw; 
        word-wrap: break-word; 
      }
      .downloadButton1{
        // position: absolute; /* Position the button */
        // top: 10px; /* Distance from the top of the container */
        // right: 10px; 
        display: flex;
        padding: 5px 10px;
        background-color: $green; /* Button background color */
        color: #ffffff; /* Button text color */
        text-decoration: none; /* Remove underline from the link */
        border: none;
        border-radius: 5px;
        align-items: center;
        justify-content: space-between;

      }
      .buttondiv{
        display: flex;
        width: 100%;
        align-items: flex-end;
        // background-color: antiquewhite;
        justify-content: flex-end;
        margin: 1rem 0rem;
      }
      .buttonAdd{
        background-color: $blue;
        color: $first;
        padding: 5px 10px;
        border: none;
        border-radius: 5px;
        &:hover{
            background-color: $indigo;
            color:$blue
        }
      }
      .popup-overlay {
        position: fixed;
        top: 10vh;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); /* Dark background */
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .popup-content {
        background-color: $first; /* Popup background color */
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        z-index: 999; /* Ensure popup is on top */
        align-items: center;
        justify-content: center;

        .label {
            width: 100%;
            text-align: left;
            > span {
                color: $red;
            }
        }
      }

      
    
}

.label {
    width: 100%;
    text-align: left;
    > span {
        color: $red;
    }
}

.profile-pic-upload {
    width:150px;
    background-color: $green;
    border-radius: 6px;
    // padding: 10px 15px;
    align-items: center;
    justify-content: center;
    display: flex;
    color:white;
    padding: 5px 10px;

  
    > span{
        color: #ffffff;
    }
}

.profile-pic-upload input[type=file] {
  width:250px;
    // height: 10%;
  opacity: 0;
    cursor: pointer;
    position: absolute;
    top: 10;
    right: 0;
   background-color: antiquewhite;
    }

.profile-pic-upload .cam-col {
    width: 70px;
    height: 70px;
    background-color: #ffffff;
    border-radius: 70px;
    overflow: hidden;
    margin: 0 auto;
}

.profile-pic-upload span {
    color: #000000;
    font-size: 13px;
    list-style: none;
    display: inline-block;
    margin-top: 15px;
}