$first:white;
$second:#090c31;
$third:#5853ff;
$dark: rgb(44,44,44);
$purple:#67214B;
$blue:#25307F;
$black:#000000;
$white:#FFFFFF;
$indigo:#252B6194;
$green:#255714;
$gray1:#D9D9D9;
$red:#FA1010;
$orange:#EB8814;
$gray2:#DFDFDF; 
$gray3:#D2CACA; 
$yellow:#E3D069;
$text1: "Montserrat";
$text2:cursive;
$text3 : "Kaushan Script"
