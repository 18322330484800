@import "colors";

.loginContainer {
  width: 100%;
  height: 100vh;
  background-color: $white;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items: center;
  > main {
    background-color: $white;
    // height: 60vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    // padding: 3rem;

    > h1 {
      text-align: center;
      font-weight: 400;
      font-size: 50px;
      font-family: $text3;
      color: $purple;
      margin: 3rem;
    }

    > form {
      //   background-color: antiquewhite;
      padding: 15px;
      width: 400px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      gap: 0.4rem;
      > div {
        flex-direction: column;
        // background-color: bisque;
        display: flex;
        width: 100%;
        > label {
          font: 400 1 $text1;
          color: $blue;
        }
        > input {
          align-self: stretch;
          // width: fit-content;
          padding: 0.5rem;
          border-radius: 5px;
          border: 1px solid #ccc;
          outline: none;
        }
      }
      > button {
         margin-top :0.8rem ;
        padding: 0.7rem;
        cursor: pointer;
        border: none;
        border-radius: 5px;
        background-color: $blue;
        color: $first;
        width: 100%;
        font: 500 1rem $text1;
        &:hover {
          // opacity :0.5;
          background-color: $indigo;
        }
      }
      > p {
        text-align: right;
        font: 400 1 $text1;
        color: $blue;
        cursor: pointer;
      }
    }
  }
  > div {
    display: flex;
    align-self: flex-end;
    // justify-content :flex-end;
    // background-color: aqua;
    width: 100vw;
    height: 40vh;
    > img {
      width: 100vw;
      height: 100%;
      align-self: flex-end; /* Align image to the bottom */
      margin-top: auto; /* Push image to the bottom */
    }
  }
}

.resetForm {
  margin: 1.5rem 0;
  // background-color: bisque;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  justify-content: flex-end;
  align-items: center;
}
.forgetDiv {
  width: 100%;
}

.resertLable {
  font: 400 1 $text1;
  // width: 20%;
  margin: 0.5rem;
  color: $blue;
}
.resetInput {
  align-self: stretch;
  width: 100%;
  padding: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: none;
}
.resetButton {
  margin: 1.5rem;
  padding: 0.7rem;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: $blue;
  color: $first;
  width: 40%;
  font: 500 1rem $text1;
  &:hover {
    // opacity :0.5;
    background-color: $indigo;
  }
}

.forgetPass {
  margin-top: 0.8rem;
}
input[type="password"]{
    // color: green;
    // font-size:2rem;
}
@media screen and (max-width: 600px) {
  .loginContainer > main > form {
    width: 100%;
  }
  .loginContainer > main > h1 {
    font-size: 1.8rem;
  }
}
