@import "colors";

.firstScreen {
    width : 100%;
    height: 100vh;
    background-color: $white;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    > main {
       background-color: $white;
       
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        // padding: 3rem;
       
        >h1 {
            text-align: center;
            font-weight: 400;
            font-size:50px ;
            font-family :  $text3; 
            color: $purple;
            margin: 3rem;
        }

        #hero {
            background-color: #f5f5f5;
            text-align: center; 
            color: #333; 
            align-items: center;
            justify-content: center;
          }
      
         

       >section {
        display: flex;
        width:80vw;
        background-color: antiquewhite;
        margin-top: 10px;
        padding : 20px;
        display: flex;
        flex-direction: column;
        > h2 {
            text-align: left;
            font-weight: 400;
            font-size:20px ;
            font-family :  $text2; 
            color: $second;
            padding: 10px;
        }
        > ul > li {
            font-family :  $text1;
            color: $dark; 
            margin-left: 20px;
       }
    }

    }
    >div{
        display: flex;
        align-self: flex-end;
        // justify-content :flex-end;
        // background-color: aqua;
         width: 100vw;
         height:40vh;
        > img {
       width: 100vw;
         height: 100%;
            align-self: flex-end; /* Align image to the bottom */
             margin-top: auto; /* Push image to the bottom */
        }

    }
   
   

    
}

.resetForm {
    margin :1.5rem 0 ;
    // background-color: bisque;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

}
.resertdiv{
    flex-direction: column;
                // background-color: bisque;
                height: 3rem;
                display: flex;
                align-items: left;
                justify-content: flex-end;
                width: 80%;
                height: 8vh;
                margin : 1rem;
}

.resertLable {
    font : 400 1 $text1;
                    // width: 20%;
                    margin: 0.5rem ;
                    color: $blue;
}
.resetInput{
    align-self:stretch ;
                     width: 100%;
                    padding: 1rem;
                    border-radius: 5px;
                    border: 1px solid #ccc;
                    outline: none;
}
.resetButton{
   
        margin :1.5rem ;
         padding: 0.7rem;
         cursor: pointer;
         border: none;
          border-radius: 5px;
          background-color: $blue;
          color: $first;
          width: 40%;
          font: 500 1rem $text1;
          &:hover{
       // opacity :0.5;
       background-color: $indigo;
}
}