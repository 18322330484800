@import "colors";

.SignUpContainer{
    width : 100%;
     min-height: 100vh !important;
    background-color: $white !important;
    display: flex;
    position: relative;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    > main {
       background-color: $white !important;
        // height: 60vh;
        min-height: 60vh !important;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
    //    padding: 3rem;
       
        >h1 {
            text-align: center;
            font-weight: 400;
            font-size:50px ;
            font-family :  $text3; 
            color: $purple;
            margin: 3rem;
        }
        >h4{
            font-size:medium;
            text-align: center;
            font-weight: 400;
            color: $blue;
            //  margin-bottom: 3rem;
        }
        >form{
            margin-top:2rem;
            width: 100% !important;
            display: flex;
            // background-color: aqua;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            >div{
                margin:1rem;
                width: 80% !important;
                display: flex;
                // background-color: aquamarine;
                align-items: center;
                justify-content: space-between;
                > div{
                    flex-direction: column;
                    // background-color: bisque;
                    height: 3rem;
                    display: flex;
                    align-items: left;
                    justify-content: flex-end;
                  width: 100%;
                    // height: 8vh;
                    margin : 1rem;
                    > label {
                        font : 400 1 $text1;
                        // width: 20%;
                        margin: 0.5rem ;
                        color: $blue;
                    }
                    > input {
                        align-self:stretch ;
                        // width: fit-content;
                        padding: 1rem;
                        border-radius: 5px;
                        border: 1px solid #ccc;
                        outline: none;
    
                    }
                 }
                 > label {
                    font : 400 1 $text1;
                    // width: 20%;
                    margin: 0.5rem ;
                    color: $blue;
                }
                > input {
                    align-self:stretch ;
                    // width: fit-content;
                    padding: 1rem;
                    border-radius: 5px;
                    border: 1px solid #ccc;
                    outline: none;

                }
            }
            > button {
                margin-top :0.3rem ;
                 padding: 0.7rem;
                 cursor: pointer;
                 border: none;
                  border-radius: 5px;
                  background-color: $blue;
                  color: $first;
                  width: 40%;
                  font: 500 1rem $text1;
                  &:hover{
               // opacity :0.5;
               background-color: $indigo;
                  }

             }
        }

       
        

    }
    >div{
        display: flex;
        align-self: flex-end;
        // justify-content :flex-end;
        // background-color: aqua;
         width: 100vw;
         height:40vh;
        > img {
            // position: absolute;
            // right: 0;
            // bottom:60;
            // z-index: 1;
       width: 100vw;
         height: 100%;
            align-self: flex-end; /* Align image to the bottom */
             margin-top: auto; /* Push image to the bottom */
        }

    }
   
}



@media screen and (max-width: 700px) {
    .SignUpContainer{
        width : 100%;
        // height: 100vh !important;
        background-color: $white !important;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        // align-items: center;
        > main {
           background-color: $white !important;
            // height: 60vh;
            width: 100vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            // padding: 3rem;
           
            > h1 {
                text-align: center;
                font-weight: 400;
                font-size:30px ;
                font-family :  $text3; 
                color: $purple;
               margin: 3rem;
            }
            > h4{
                font-size:medium;
                text-align: center;
                font-weight: 400;
                color: $blue;
                //  margin-bottom: 3rem;
            }
            > form {
                // margin-top: 5rem;
                width: 100% !important;
                display: flex;
                // background-color: aqua;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                > label {
                    font : 400 1 $text1;
                    // width: 20%;
                    margin: 0.5rem ;
                    color: $blue;
                    text-align: left;
                }
                > div{
                     margin:0rem;
                    width: 80% !important;
                    display: flex;
                //    background-color: aquamarine;
                    align-items: center;
                    flex-direction: column;
                    > div{
                        flex-direction: column;
                        // background-color: bisque;
                        // height: 3rem;
                        display: flex;
                        align-items: left;
                        justify-content: flex-end;
                      width: 100%;
                        // height: 8vh;
                        margin : 2rem;
                        > label {
                            font : 400 1 $text1;
                            // width: 20%;
                            margin: 0.5rem ;
                            color: $blue;
                        }
                        > input {
                            align-self:stretch ;
                            // width: fit-content;
                            padding: 1rem;
                            border-radius: 5px;
                            border: 1px solid #ccc;
                            outline: none;
        
                        }
                     }
                     > label {
                        font : 400 1 $text1;
                        // width: 20%;
                        margin: 0.5rem ;
                        color: $blue;
                    }
                    > input {
                        align-self:stretch ;
                        // width: fit-content;
                        padding: 1rem;
                        border-radius: 5px;
                        border: 1px solid #ccc;
                        outline: none;
    
                    }
                }
                > button {
                   
                     padding: 0.7rem;
                     cursor: pointer;
                     border: none;
                      border-radius: 5px;
                      background-color: $blue;
                      color: $first;
                      width:70%;
                      font: 500 1rem $text1;
                      &:hover{
                   // opacity :0.5;
                   background-color: $indigo;
                      }
    
                 }
            }
    
           
            
    
        }
        >div{
            display: flex;
            align-self: flex-end;
            // display: none;
             width: 100vw;
             height:40vh;
            > img {
              
           width: 100vw;
             height: 100%;
                align-self: flex-end; /* Align image to the bottom */
                 margin-top: auto; /* Push image to the bottom */
            }
    
        }
       
    }
}

.autocomplete-dropdown-container {
    position: absolute;
    background-color: #ffffff;
    border: 1px solid #ccc;
    max-height: 200px; /* Adjust height as needed */
    overflow-y: auto;
    z-index: 1000; /* Ensure the dropdown appears above other elements */
  }
  
  .suggestion-item {
    padding: 8px 12px;
    font-size: 14px;
    cursor: pointer;
  }
  
  .suggestion-item:hover {
    background-color: #fafafa;
  }
  
  .suggestion-item--active {
    background-color: #fafafa;
  }
