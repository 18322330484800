@import "colors";

footer {
  background-color:$first;
  height: 5vh;
  width: 100%;
 padding: 3rem;
  color: $dark;
  justify-content: space-between;
  background-color: $purple;
//   grid-template-columns: 4fr 1fr;

  > div {
    display: flex;
   // background-color:blueviolet ;
    flex-direction: column;
    justify-content: center;
    > a {
      font: 50 400 $text1;
      text-transform: uppercase;
      color: $white;
      > span {
        font:20 1rem  $text1;
      }
    }
    
    > h3 {
      font: 300 1.7rem $text1;
      margin: 0.3rem 0;
      text-align: center;
    }
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;

      > div {
        display: flex;
        // background-color: aqua;

        align-items: center;
        justify-content: space-around;
        > a {
          color: $dark;
          margin: 1rem;
          font: 900 1rem $text2;
          &:hover {
            color: $third;
          }
        }
      }
    }
  }
}
