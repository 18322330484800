@import "../colors";


.AdminHome {
    
    display:flex;
    // width: 100vw;
    min-height: 75vh;
    // flex-direction: row;
    // background-color: white;
    margin-top: 2rem;
    // overflow-x: hidden;
    // white-space:nowrap;

    > main {

      margin: 0rem 1rem;
      // background-color: antiquewhite;
      width: 90%;

      > div{
         margin: 0.5rem 0rem ;
         background-color: $gray1;
         display: flex;
         flex-direction: row;
         justify-content: space-between;
         padding : 1rem;
         border-radius: 10px;
        >div{
          width :20%;
          // background-color: burlywood;
          // margin: 3px; 
          display: flex;
          flex-direction: row;
          align-items: center;
         
          >p {
            font-family: 'Helvetica Neue';
            color:$dark;
            font-size: large;
            font-weight: 400;
          
          }
          >a {
            font-family: 'Helvetica Neue';
            color:$first;
            font-size: large;
            font-weight: 400;
            padding: 0.2rem 0.5rem;
            background-color: $blue;
            margin:0.5rem;
          border-radius: 5px;
          cursor: pointer;
          }
        }
      }

    }
  }
  
  .side-drawer.open {
    transform: translateX(0);
  }
  
  .toggle-button {
    position: absolute;
    top: 1rem;
    left: 1rem;
    font-size: 1.5rem;
    cursor: pointer;
  }